
export const getStatusCardAdmin = (props) => {
    return [
      {
        "icon": "bx bxs-user-detail",
        "count": props.fltr_date.length,
        "title": "Activités commerciales"
      }
    ];
  }
  
